
.footer {
    background-color: #1F51FF;
    height: 25rem;
    border-radius: 1rem;
    width: 100%;
    margin: 1rem;
}

.footerContainer .blue {
    background-color: #F2F4F5;
}

.blue h1 {
    color: #1F51FF;
}

.footerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerMain {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerMain h1 {
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
}

.emailBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30rem;
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.emailBox input {
    width: 24rem;
}

.emailBox input:focus {
    outline: none;
}

.mainCta {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.formInput {
    border: 0px;
    background-color: transparent;
}

.footerLinks {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    flex-direction: row;
}

#link {
    color: rgba(255, 255, 255, 0.6);
    text-decoration: none;
    font-weight: 400;
}

.blue #link {
    color: rgba(36, 97, 255, 0.60);;
}

#link:hover {
    color: white;
}

.middle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 22rem;
}

.mailInput {
    display: flex;
}

.mailInput button {
    margin-left: -3.5rem;
    margin-top: 0.15rem;
    width: 3rem;
    height: 2.5rem;
    outline: none;
    border: none;
    background-color: white;
}

.middleCenter{
    display: flex;
    justify-content: center;
}

.middle h1{
    font-size: 5rem;
    padding-left: 30%;
}

.middle input {
    width: 30rem;
    height: 3rem;
    border-radius: 0.75rem;
    outline: none;
    border: none;
}

.bottom {
    display: flex;
    justify-content: space-evenly;
}

@media only screen and (max-width: 600px){
    
    .middle input {
        width: 18rem;
    }

    .linkContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;
    }

    .footerLinks {
        height: 40%;
    }

    .footerMain {
        height: 60%;
    }

    .footer {
        height: 25rem;
    }

    .emailBox {
        width: 18rem;
    }

    .emailBox input {
        width: 14rem;
    }

    .middle h1 {
        padding-left: 20%;
    }
}