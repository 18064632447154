.App {
    background-image: none;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: sans-serif;
    margin-left: -1px;
}

.App.dark {
    background-image: url('./assets/gradient-long-new.svg');
    background-color: #100B1C;
}

.App.light {
    background-image: none;
}

.App.blue {
    background-image: none;
    background: #2461FF;
}

html * {
    font-family: 'system-ui', sans-serif;
}

@media only screen and (max-width: 600px){
    .App.slash {
        background-image: url('./assets/mobilegradient.svg');
    }
}