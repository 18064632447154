
.mainContainer {
    display: flex;
    color: black;
    justify-content: space-around;
    padding-top: 10rem;
    padding-bottom: 11rem;
    text-align: center;
}

.logoRender {
    width: 40rem;
    position: absolute;
    left: -8rem;
    top: 3rem;
}

.mainText {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mainText p {
    color: rgba(255, 255, 255, 0.60);
}

.mainContainer h1 {
    color: white;
}

.mainButtons {
    display: flex;
}

.mainButtons button {
    height: 2rem;
    padding: 0.375rem 0.75rem;
    outline: none;
    border: none;
    border-radius: 0.75rem;
}

#people {
    background-color: #2461FF;
    color: white;
}

#email {
    color: black;
}

@media only screen and (max-width: 600px){
    .mainContainer{
        flex-direction: column;
    }

    .logoRender{
        display: none;
    }

    .mainText {
        align-items: center;
    }

    .mainButtons {
        flex-direction: column-reverse;
    }
}