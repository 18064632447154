.navbar {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    padding-left: 1rem;
    padding-right: 1rem;
}

.light {
    color: black;
}

.dark, .blue {
    color: white;
}

.navbar button{
    border: none;
    outline: none;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0);
}

.middleLinks {
    padding-top: 2rem;
    display: flex;
}

.loginB {
    padding-top: 2rem;
    font-weight: 800;
}

.logo {
    padding-top: 1.75rem;
    display: flex;
    cursor: pointer;
}

.logo button {
    color: white;
    font-size: 1.75rem;
    font-weight: 600;
}

.product, .about {
    text-decoration: none;
    padding-right: 3rem;
    cursor: pointer;
}

.loginB .light {
    background-color: blue;
    color: white;
    padding: 1rem;
    font-weight: 500;
    font-size: 1rem;
    padding: 0.5rem 0.625rem;
    border-radius: 0.5rem;
    background: #2461FF;
}

.login {
    color: black;
    text-decoration: none;
    background: #F2F4F5;
    border-radius: 0.5rem;
    padding: 0.5rem 0.625rem;
    cursor: pointer;
}

@media only screen and (max-width: 600px){
    .product, .about {
        padding-right: 20%;
    }

    .product {
        margin-left: -20%;
    }
}
