.mainContainer {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.mainContainer h1 {
    font-size: 3rem;
    padding-bottom: 1rem;
    text-align: center;
}

.mainContainer p {
    color: rgba(0, 0, 0, 0.60);
    width: 50%;
    text-align: center;
    padding-bottom: 1rem;
}

.mainButtons {
    display: flex;
    justify-content: center;
}

.mainButtons button {
    border: none;
    outline: none;
    border-radius: 0.75rem;
    padding: 0.375rem 0.75rem;
}

#email {
    background-color: white;
    color: #2461FF;
    margin-right: 2rem;
}

#call {
    background-color: #2461FF;
    color: white;
}