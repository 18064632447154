.mainContainer {
    color: black;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heroContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
}

.subHeroText {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    justify-content: center;
    margin-bottom: 0;
    text-align: center;

    p {
        text-align: center;
        margin-bottom: 0;
        color: rgba(0, 0, 0, 0.6);
    }
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.emailButton {
    button {
        background-color: white;
        outline: none;
        color: black;
        border-radius: 0.8rem;
        border: none;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-bottom: 0.375rem;
        padding-top: 0.375rem;
    }

    img {
        padding-left: 0.25rem;
    }
}

.scheduleButton {
    button {
        background-color: #2461FF;
        outline: none;
        color: white;
        border-radius: 0.8rem;
        border: none;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-bottom: 0.375rem;
        padding-top: 0.375rem;
    }

    img {
        padding-left: 0.25rem;
    }
}