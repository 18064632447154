@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

body{
    color: white;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
}

.mainPage {
    display: flex;
    justify-content: space-around;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.mainText {
    flex-direction: column;
    justify-content: left;
}

.mainText h1 {
    padding-bottom: 1rem;
}

.mainButtons{
    padding-right: 2rem;
    display: flex;
    gap: 1.5rem;
}

.mainButtons button {
    outline: none;
    border: none;
    display: flex;
    height: 2rem;
    padding: 0.375rem 0.75rem;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.75rem;
    background: #A7C0FF;
}

#derm {
    background-color: rgba(255, 255, 255, 0.0);
    color: white;
    margin-left: 0.5rem;
}

.description {
    color: rgba(255, 255, 255, 0.60);
    line-height: 60%;
    padding-bottom: 1rem;
    font-weight: 500;
}

.icon {
    color: white;
    justify-content: right;
}

@media only screen and (max-width: 600px){
    .mainPage {
        padding-top: 5rem;
        padding-bottom: 5rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mainText{
        justify-content: center;
        align-items: center;
        margin-left: 10%;
    }

    .icon {
        padding-top: 5rem;
    }

    .mainButtons button {
        text-align: center;
        line-height: 1rem;
    }
}

.tooling{
    background-color: rgba(255, 255, 255, 0.10);
    background-image: url('../assets/backgroundlines.svg');
    color: white;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 9rem;
    padding-bottom: 9rem;
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    text-align: center;
}

.tooling h2 {
    font-weight: 700;
}

.mid {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10rem;
}

.midFormat {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 45%;
}

.levGraphic{
    justify-content: right;
}

.leverage p{
    color: rgba(255, 255, 255, 0.60);
}

#flexible {
    color: #A7C0FF;
    opacity: 50%;
    font-weight: 200;
    font-family: 'Space Mono', monospace;
}

.features {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-around;
}

.guidance {
    margin-right: 0rem;
}

.features p {
    line-height: 1.3rem;
    color: rgba(255, 255, 255, 0.60);
}

.features button {
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    outline: none;
    border: none;
    gap: 0.75rem;
    display: flex;
    align-items: center;
}

.guidance button {
    background-color: #7CB9E8;
}

.features h3 {
    padding-bottom: 0.15rem;
}

#upscImg {
    padding-bottom: 1rem;
    width: 4rem;
}

.upscale button {
    background: #FFC7A7;
}

@media only screen and (max-width: 600px){
    .leveraging {
        flex-direction: column;
    }

    .leverage {
        width: 80%;
        margin-left: 2rem;
        margin-bottom: 2rem;
    }

    .levGraphic {
        margin-left: 2rem;
    }

    .features {
        margin-left: 2rem;
        width: 90%;
        flex-direction: column;
    }

    .guidance {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .mid {
        margin-bottom: 5rem;
    }
}


.telehealth ul {
    list-style-type: none;
}

.telehealth {
    background-color: white;
    margin-right: 1rem;
    width: 60%;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
}

.teleImage {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.clinicalResearchImg {
    width: 14rem;
}

.teleFormat {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.telehealth button {
    background: #2461FF;
    color: white;
    margin-left: 2rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    outline: none;
    border: none;
}

.applications {
    display: flex;
    flex-direction: row;
    width: 96%;
    margin: 2%;
    margin-bottom: 7rem;
}

.secondary {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-evenly;
}

.commerce {
    background-color: white;
    color: black;
    height: 60%;
    border-radius: 1rem;
    margin-bottom: 3%;
}

.commerceHeader {
    display: flex;
    justify-content: space-between;
    margin-right: 1rem;
}

.commerceHeader button {
    background: #9224FF;
    color: white;
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    outline: none;
    border: none;
    position: relative;
    top: 0rem;
}

.commerceHeader {
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.commerceFooter {
    margin-left: -2rem;
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.60);
}

.commerceFooter ul {
    list-style-type: none;
}

.commerceFooter span {
    padding-left: 1rem;
}

.commerceFormat {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.commerceMid {
    margin-left: 0rem;
    margin-right: 1rem;
}

.trials {
    background-color: white;
    color: black;
    height: 38%;
    border-radius: 1rem;
}

.teleHeader {
    margin: 2rem;
    margin-top: 0rem;   
}

.teleBottom {
    margin-left: 0.5rem;
    margin-top: 4rem;
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.60);
}

.teleBottom span{
    padding-left: 1rem;
}

.teleBottom button {
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}

.trialsHeader {
    display: flex;
    justify-content: space-between;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#clinicalTrialImg {
    margin-bottom: 1rem;
}

.trialsHeader button{
    background: #FF2459;
    color: black;
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
    outline: none;
    border: none;
    position: relative;
    top: 0rem;
}

.trialsFormat {
    margin-left: 2rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 750px){
    .applications {
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .telehealth{
        width: 100%;
        margin-bottom: 1rem;
    }

    .teleImage img{
        display: none;
    }

    .secondary{
        width: 100%;
    }

    .commerce {
        margin-bottom: 1rem;
    }

    .commerceFooter span {
        padding-left: 0.3rem;
    }

    .teleBottom span{
        padding-left: 0.3rem;
    }
}

#ludicrous {
    color: #A7C0FF;
    opacity: 50%;
    font-weight: 200;
    font-family: 'Space Mono', monospace;
    display: flex;
    justify-content: center;
}

#integrateDesc {
    color: rgba(255, 255, 255, 0.60);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.integration {
    display: flex;
    justify-content: center;
}

.intFormat {
    width: 40%;
}

.intFormat h1{
    display: flex;
    justify-content: center;
}

.intFormat button{
    border-radius: 0.5rem;
    background: rgba(36, 97, 255, 0.20);
    color: #2461FF;
    border: none;
    outline: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.moments {
    width: 50%;
    margin-left: 25%;
    margin-bottom: 10rem;
}

.momFormat {
    display: flex;
    justify-content: space-around;
}

.momFormat img {
    margin-left: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

@media only screen and (max-width: 750px){
    .integration {
        padding-top: 3rem;
    }
    
    .intFormat {
        width: 90%;
    }

    .intFormat button {
        margin-left: 40%;
    }

    .fastapi {
        margin-right: 2rem;
    }

    .seamless {
        margin-right: 2rem;
    }

    .moments{
        width: 80%;
        margin-left: 20%;
        margin-bottom: 5rem;
    }

    .momFormat img{
        margin-left: 0.5rem;
    }

    .momFormat {
        justify-content: center;
    }
}

@media only screen and (max-width: 600px){
    .intFormat button {
        margin-left: 30%;
    }

    .tooling {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .mainButtons {
        flex-direction: column;
    }

    #derm {
        padding-left: 0rem;
    }

    .description {
        line-height: normal;
    }

    .mainText {
        width: 70%;
        margin-left: 0%;
    }

    .midFormat {
        width: 80%;
    }

    .mid {
        flex-direction: column;
        gap: 4rem;
    }

    .teleBottom {
        margin-top: 0rem;
        margin-bottom: 2rem;
    }

    .teleHeader {
        margin-top: 2rem;
    }

    .trialsFormat {
        margin-bottom: 1rem;
    }

    .intFormat {
        h1 {
            font-size: 1.5rem;
        }
    }

    #integrateDesc {
        font-size: 1.1rem;
    }

    .integrationContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .moments {
        margin-top: 4rem;
        width: 80%;
        margin-left: 0;
    }

    .momFormat {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .fastapi {
        margin: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
            margin: 0;
        }

        h3 {
            font-size: 1.2rem;
            text-align: center;
        }
    }

    .seamless {
        margin: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
            margin: 0;
        }

        h3 {
            font-size: 1.2rem;
            text-align: center;
        }
    }
}
