.nav {
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: space-between;
}

.menu {
    display: flex;
    flex-direction: column;
    font-size: x-large;
    line-height: 3rem;
}

.logo {
    padding-top: 0.25rem;
}

/* .offcanvas {
    offc
} */