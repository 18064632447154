.header {
    background-image: url("../assets/asset-blue-circle.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.header h1 {
    color: black;
    font-size: 4rem;
    text-align: center;
    padding-top: 15rem;
    padding-bottom: 15rem;
}

.period {
    color: #3F22FF;
}

.middle {
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
}

.middle h2{
    color: black;
    width: 35%;
    text-align: center;
}

.bottom {
    display: flex;
    flex-direction: column;
    color: black;
    align-items: center;
    padding-bottom: 10rem;
}

.bottom p {
    color: rgba(0, 0, 0, 0.60);
    font-weight: 500;
    text-align: center;
    width: 55%;
}

.bottom button {
    background: rgba(36, 97, 255, 0.20);
    border-radius: 0.75rem;
    height: 2rem;
    padding: 0.375rem 0.75rem;
    border: none;
    outline: none;
    color: #2461FF;;
}

#smallText {
    color: rgba(36, 97, 255, 0.50);
    font-family: Space Mono;
    font-weight: 400;
}

